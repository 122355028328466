.rbc-active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.rbc-btn-group > button{
    border-radius: 0;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid black;
}

