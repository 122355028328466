body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

html {
  height: 100%;
}

.bootstrap {
  
  #root {
    height: 100%;
  }
  
  
  .btn-bar {
    margin-bottom: 10px;
  }
  
  .login-container-heading {
    text-align: center;
  }
  
  .login-container-inner {
    margin-top: 200px;
    padding: 10px;
    background-color: white;
    border: 1px solid #20435a;
    
  }
  
  .login-container-inner h1 {
    text-align: center;
    font-size: 40px;
  }
  
  .new-ticket-table-left {
    width: 20%;
  }
  
  .new-ticket-table-right {
    width: 40%;
  }
  
  .new-ticket-table-middle {
    border-width: 0 1px;
    border-style: solid;
    border-color: #20435a;
    width: 40%;
  }
  
  .new-ticket-table-left, .new-ticket-table-right, .new-ticket-table-middle {
    float: left;
    height: 400px;
    position: relative;
    overflow: auto;
  }
  
  .new-ticket-customer-panel {
    height: 400px;
    position: relative;
    overflow: auto;
    padding: 0 !important;
  }
  
  .newUserPanel {
    overflow: auto;
    height: 550px;
  }
  
  .newTicketBottomPanel {
    overflow: auto;
    height: 440px;
  }
  
  .showTicketLinkPanel {
    overflow: auto;
    height: 300px;
  }
  
  
  .nFooter {
    font-size: 80%;
    color: #777777
  }
  
  .nHeader {
    
    display: inline;
    
  }
  
  .notification {
    position: absolute;
    right: -8px;
    top: 30px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    display: inline;
    background-color: red;
    z-index: 1001;
    color: white;
  }
  
  .normal-link {
    color: #23527c;
    text-decoration: underline;
  }
  
  .live-support {
    position: fixed;
    z-index: 2000;
    bottom: 0;
    right: 10%;
    width: 400px;
  }
  
  .live-support-panel {
    box-shadow: 10px 10px 80px #20435a;
  }
  
  .permission-level-1 {
    float: left
  }
  
  .specialLink:hover {
    color: black;
  }
  
  .nav-alert-div {
    padding: 10px !important;
    width: 300px;
    margin: 5px !important;
    line-height: 16px;
  }
  
  .nav-alert-div img {
    height: 16px;
  }
  
  .colorful-slider {
    height: 10px;
    -webkit-appearance: none;
    background: white !important;
    background: -webkit-linear-gradient(left, green, yellow, red) !important;
    background: -o-linear-gradient(right, green, yellow, red) !important;
    background: -moz-linear-gradient(right, green, yellow, red) !important;
    background: linear-gradient(to right, green, yellow, red) !important;
  }
  
  .colorful-slider::-webkit-slider-thumb {
    border-radius: 3px;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 10px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #b0b0b0; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  
  .colorful-slider::-moz-range-thumb {
    border-radius: 3px;
    width: 10px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #b0b0b0; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  
  .glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
  }
  
  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
  
  .frontend-nav .navbar,
  .frontend-nav .well {
    margin-bottom: 0;
  }
  
  .frontend-nav {
    margin-bottom: 20px;
  }
  
  .ui-autocomplete-category {
    font-weight: bold;
    padding: .2em .4em;
    margin: .8em 0 .2em;
    line-height: 1.5;
  }
  
  a.disabled {
    /* Make the disabled links grayish*/
    color: gray;
    /* And disable the pointer events */
    pointer-events: none;
    cursor: not-allowed;
  }
  
  .panel-footer > .pagination {
    margin: 0;
  }
  
  .panel-footer > .pagination > .active > a,
  .panel-footer > .pagination > .active > span,
  .panel-footer > .pagination > .active > a:hover,
  .panel-footer > .pagination > .active > span:hover,
  .panel-footer > .pagination > .active > a:focus,
  .panel-footer > .pagination > .active > span:focus {
    background-color: #0F527D;
    border-color: #0F527D;
  }
  
  .alert-window {
    position: fixed;
    width: 276px;
    bottom: 0;
    left: 0;
    margin-left: 5px;
    direction: ltr;
    z-index: 1060;
    
  }
  
  .alert-window > .alert-list {
    max-height: 300px !important;
    overflow-y: auto;
  }
  
  .alert-window > .alert-list > .alert {
    margin-bottom: 5px;
    padding: 10px;
  }
  
  .editor-toolbar {
    border: 1px solid black !important;
  }
  
  .editor-wysiwyg {
    min-height: 100px;
    max-height: 300px !important;
    border: 1px solid black !important;
    padding: 10px !important;
    border-radius: 2px !important;
  }
  
  .editor-wysiwyg .public-DraftStyleDefault-block {
    margin: 0;
  }
  
  .ql-container.ql-snow {
    border: 1px solid black !important;
    border-top: 0 !important;
  }
  
  .ql-toolbar.ql-snow {
    border: 1px solid black !important;
  }
  
  .ticket-signature-canvas {
    width: 100%;
    height: 300px;
    border: 1px solid black;
  }
  
  .ticket-description-panel h1 {
    margin-top: 0;
  }
  
  .sitebar {
    padding-top: 10px;
    /*height: 100%;*/
    background-color: #EFEFEF;
  }
  
  .sitebar-fixed {
    position: fixed !important;
    z-index: 2000;
  }
  
  .ticket-add-device-modal {
    width: 90% !important;
  }
  
  .loading-screen, .access-denied-screen {
    width: 100%;
    text-align: center;
  }
  
  .access-denied-screen p {
    font-size: 25px;
  }
  
  .loading-screen-logo {
    margin-bottom: 20px;
  }
  
  .image-overlay-container {
    position: relative;
  }
  
  .image-overlay-corner {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .image-with-overlay {
    opacity: 1;
    display: block;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  
  .image-overlay-container:hover .image-with-overlay {
    opacity: 0.3;
  }
  
  .image-overlay-container:hover .image-overlay-corner {
    opacity: 1;
  }
  
  .search-result-panel {
    cursor: pointer;
  }
  
  
  .search-result-panel:hover {
    background-color: #f5f5f5;
  }
  
  .search-result-panel .panel-body {
    padding: 10px;
  }
  
  .search-result-panel .panel-body h3 {
    margin: 0 0 5px 0;
  }
  
  .search-result-footer {
    font-size: 80%;
    opacity: 0.8;
  }
  
  .search-result-header {
    margin-bottom: 10px;
  }
  
  .filter-value {
    cursor: pointer;
  }
  
  .filter-value:hover {
    color: #848484;
  }
  
  .component-table td {
    vertical-align: middle !important;
  }
  
  .badge-block {
    display: block;
    width: 100%;
  }
  
  
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  
  .dragMe {
    cursor: move !important;
  }
  
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
  
  .dashboard-heading {
    margin-right: 5px;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    float: left;
  }
  
  .ticket-device-customer-dl {
    margin-bottom: 0 !important;
  }
  
  .autocomplete {
    position: relative;
  }
  
  .search-autocomplete-list {
    position: absolute;
    border: 1px solid #20435a;
    border-top: none;
    z-index: 99;
    top: 100%;
    padding: 5px;
    left: 0;
    right: 1px;
    background-color: white;
  }
  
  .search-autocomplete-list > .autocomplete-list-title {
    font-weight: bold;
  }
  
  .search-autocomplete-list > ul {
    padding: 0;
    margin: 0;
  }
  
  .search-autocomplete-list > ul > li {
    list-style: none;
    padding: 0 0 0 1em;
    cursor: pointer;
  }
  
  .search-autocomplete-list > ul > li:hover {
    background-color: #e8e8e8;
  }
}